import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { Providers } from "./components/provider/Providers";
import { Layout } from "./components/layout";
import { sentryCreateBrowserRouter } from "./sentry";
import { ErrorBoundary } from "./components/layout/ErrorBoundary";

const queryClient = new QueryClient();

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Providers />,
    children: [
      {
        path: "",
        element: <Layout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "",
            async lazy() {
              const { KYCProvider } = await import(
                "./components/provider/KYCProvider"
              );
              return { Component: KYCProvider };
            },
            children: [
              {
                path: "",
                async lazy() {
                  const { Home } = await import("./page/home");
                  return { Component: Home };
                },
              },
              {
                path: "",
                async lazy() {
                  const { loader } = await import(
                    "./components/provider/AuthGuard"
                  );
                  return { loader: loader };
                },
                children: [
                  {
                    path: "bank_transfer_instruction",
                    async lazy() {
                      const [{ BankTransferInstruction }, { loader }] =
                        await Promise.all([
                          import("./page/BankTransferInstruction"),
                          import("./page/BankTransferInstruction/loader"),
                        ]);
                      return { Component: BankTransferInstruction, loader };
                    },
                  },
                  {
                    path: "kyc",
                    async lazy() {
                      const { KYC } = await import("./page/KYC");
                      return { Component: KYC };
                    },
                  },
                  {
                    path: "orders/:id/pay",
                    async lazy() {
                      const { OrderPay } = await import("./page/OrderPay");
                      return { Component: OrderPay };
                    },
                  },
                  {
                    path: "orders",
                    async lazy() {
                      const { Orders } = await import("./page/order/Orders");
                      return { Component: Orders };
                    },
                  },
                  {
                    path: "orders/:id",
                    async lazy() {
                      const { Order } = await import("./page/order/Order");
                      return { Component: Order };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "auth",
            async lazy() {
              const { Auth } = await import("./page/auth");
              return { Component: Auth };
            },
          },
          {
            path: "auth/email",
            async lazy() {
              const { AuthWithEmail } = await import("./page/auth/Email");
              return { Component: AuthWithEmail };
            },
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
